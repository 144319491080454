import React from "react";
import Carousel from 'react-elastic-carousel'
import Card from '../Card'
import '../styles/Carousel.css'
import '../styles/Card.css'


function Slider() {

    const breakPoints = [
        {width: 900, itemsToShow: 1},
        {width: 1300, itemsToShow: 2},
        {width: 1700, itemsToShow: 2},
        {width: 2200, itemsToShow: 2},
    ]

  return (
    
    <div className="Carousel">
        <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={2000}>
            <Card number=<img className="card_image" src="../images/Stlp1.png"></img> />
            <Card number=<img className="card_image" src="../images/3D1.png"></img> />
            <Card number=<img className="card_image" src="../images/K1.png"></img> />
            <Card number=<img className="card_image" src="../images/BSt1.png"></img> />
            <Card number=<img className="card_image" src="../images/k2.png"></img> />
            <Card number=<img className="card_image" src="../images/Sk1.png"></img> />
        </Carousel>
    </div>
  )
}

export {Slider}