import React from 'react'
import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import Slider1 from '../images/slider1.png'
import Slider2 from '../images/slider2.png'
import Slider3 from '../images/slider3.png'
import Slider4 from '../images/slider4.png'



function Slider() {
  return (
<div id="carouselMaterialStyle" class="carousel slide carousel-fade" data-mdb-ride="carousel">
  <h3 className='portfolio_h3'>PORTFOLIO</h3>
  <div class="carousel-indicators">
    <button type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide-to="0" class="active" aria-current="true"
      aria-label="Slide 1"></button>
    <button type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide-to="2" aria-label="Slide 3"></button>
  </div>

  <div class="carousel-inner rounded-5 shadow-4-strong">
    <div class="carousel-item active">
      <img src={Slider1} class="d-block w-100"
        alt="Sunset Over the City" />
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div> */}
    </div>

    <div class="carousel-item">
      <img src={Slider2} class="d-block w-100"
        alt="Canyon at Nigh" />
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div> */}
    </div>

    <div class="carousel-item">
      <img src={Slider3} class="d-block w-100"
        alt="Canyon at Nigh" />
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div> */}
    </div>


    <div class="carousel-item">
      <img src={Slider4} class="d-block w-100"
        alt="Cliff Above a Stormy Sea" />
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </div> */}
    </div>
  </div>
 
  <button class="carousel-control-prev" type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  )
}

export {Slider}