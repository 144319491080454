import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"


function Navbar() {
    const navRef = useRef();

    const showNavbar =()=> {
        navRef.current.classList.toggle("responsive_nav")
    }

  return (
    <header>
        <Link to="/"><img className="logo_image" src="./images/VI_Logo.jpeg" alt="Logo" /></Link>
        <nav ref={navRef}>
            <Link to="/" onClick={showNavbar}>HEM</Link>
            <Link to="/about" onClick={showNavbar}>SÅ JOBBAR JAG</Link>
            {/* <Link to="/services">TJÄNSER</Link> */}
            <Link to="/contacts" onClick={showNavbar}>KONTAKT</Link>
            <button className='nav-btn nav-close-btn' onClick={showNavbar}>
                <AiOutlineClose />
            </button>
        </nav>
        <button className='nav-btn' onClick={showNavbar}>
            <AiOutlineMenu />
        </button>
    </header>
  )
}

export {Navbar}