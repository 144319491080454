import React from 'react';
import {Link} from 'react-router-dom';
import '../styles/Services.css';
import {Slider} from '../components/Carousel';

function Services() {
  return (
  <div className='services_container_homepage'>
      {/* OM MIG PART */}
        <div className="services_om_mig">
          <div className="services_om_mig_text">
            <h2 className='services_om_mig_h2'>OM MIG</h2>
            <p className='services_om_mig_i'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus quaerat saepe velit aspernatur excepturi? Voluptatem, deserunt!</p>
            <p className='services_om_mig_p'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus quaerat saepe velit aspernatur excepturi? Voluptatem, deserunt! Error quaerat ullam eligendi ad maxime. Reiciendis quae in delectus amet ad perspiciatis porro aliquam, quod et rem iusto beatae molestiae excepturi. Neque!</p>
          </div>
          <div className="services_om_mig_image">
            <img className='services_img_ommig' src="./images/jenny.jpeg" alt="Jenny Nyberg" />
          </div>
        </div>
        {/* SERVICES PART */}
        <div className="services_services">
          <h3 className="services_services_main_h3">TJÄNSER</h3>
          <p className="services_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et, reiciendis sed ullam modi assumenda temporibus id facilis repudiandae sunt quasi. Ipsa, cum?</p>
        </div>
        {/* SERVICES LIST PART */}
        <div className="services_services_list">
          <div className="services_services_list_item">
            <h3 className="services_services_h3">INREDNING</h3>
            <img className='services_services_icon' src="./images/stol.png" alt="Icon" />
            <p className="services_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et?</p>
          </div>
           <div className="services_services_list_item">
            <h3 className="services_services_h3">RENSNING/ORGANISERING</h3>
            <img className='services_services_icon' src="./images/skap.png" alt="Icon" />
            <p className="services_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et?</p>
          </div>
           <div className="services_services_list_item">
            <h3 className="services_services_h3">HOMESTYLING</h3>
            <img className='services_services_icon' src="./images/lamp.png" alt="Icon" />
            <p className="services_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et?</p>
          </div>
        </div>        
        {/* BACKGRUND BILD PART */}
        <div className="services_background_image">
            <img className="services_image_nature" src="./images/nature.jpg" alt="Nature image"/>
          <div className="services_background_image_text">
            <p>Vill du boka konsultation?<br></br> Hör av dig med ditt projekt - jag jobbar både<br></br>med privatpersoner och företag</p>
            <button className='services_contacts_btn'><Link className='services_contacts_btn' to="/contacts">KONTAKTA MIG</Link></button>
          </div>
        </div>
        {/* PRISER PART*/}
        <div className="prices">
        <div className="prices_services">
          <h3 className="prices_services_main_h3">PRISER</h3>
          <p className="prices_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et, reiciendis sed ullam modi assumenda temporibus id facilis repudiandae sunt quasi. Ipsa, cum?</p>
        </div>
        {/* SERVICES LIST PART */}
        <div className="prices_services_list">
          <div className="prices_services_list_item">
            <h3 className="prices_services_h3">INREDNING</h3>
            {/* <img className='prices_services_icon' src="./images/stol.png" alt="Icon" /> */}
            <p className="prices_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et?</p>
          </div>
           <div className="prices_services_list_item">
            <h3 className="prices_services_h3">RENSNING/ORGANISERING</h3>
            {/* <img className='prices_services_icon' src="./images/skap.png" alt="Icon" /> */}
            <p className="prices_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et?</p>
          </div>
           <div className="prices_services_list_item">
            <h3 className="prices_services_h3">HOMESTYLING</h3>
            {/* <img className='prices_services_icon' src="./images/lamp.png" alt="Icon" /> */}
            <p className="prices_services_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et quos aperiam eos nam nostrum? Voluptatem enim cumque laboriosam, tempora numquam est et?</p>
          </div>
          </div>
        </div>
          <Slider />
    </div>
  )
}

export {Services}