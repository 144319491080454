import React from 'react';
import './App.css';
import {Header} from './components/Header';
import { Routes, Route } from 'react-router-dom';

//IMPORT PAGES FROM PAGES

import { Homepage } from './pages/Homepage'
import { About } from './pages/About'
import { Contacts } from './pages/Contacts'
import { Portfolio } from './pages/Portfolio'
import { Prices } from './pages/Prices'
import { Services } from './pages/Services'
import { NotFound } from './pages/NotFound'


function App() {
  return (
    <div className="App">

      {/* ROUTES */}

      <Routes>
        <Route path='/' element={<Header/>}>
          <Route index element={<Homepage/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/prices" element={<Prices/>}/>
          <Route path="/portfolio" element={<Portfolio/>}/>
          <Route path="/contacts" element={<Contacts/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
