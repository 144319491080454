import React from 'react';
import {Link} from 'react-router-dom';
import '../styles/Homepage.css';
import {Slider} from '../components/Slider';

import Jenny from '../images/jenny.jpeg'
import StolIcon from '../images/stol.png'
import SkapIcon from '../images/skap.png'
import LampIcon from '../images/lamp.png'


function Homepage() {
  return (
    <div className='container_homepage'>
      {/* <img className='main_img_header' src="./images/11.jpeg" alt="MAin" />
      <div className="main_image">
      </div> */}
      {/* OM MIG PART */}
        {/* <h2 className='om_mig_h2'>OM MIG</h2> */}
        <div className="om_mig">
          <div className="om_mig_text">
            <p className='om_mig_p'>Genom rådgivning och visualisering hjälper jag till att se
              möjligheterna i en bostad. Med utgångspunkt i kundens
              personliga stil ritar jag inredningsförslag fyllda med
              smarta och snygga förvaringslösningar - design och
              organisering som håller över tid och ger en lugnare
              vardag.
            </p>
          </div>
          <div className="om_mig_image">
            <img className='img_ommig' src={Jenny} alt="Jenny Nyberg" />
          </div>
        </div>
        {/* SERVICES PART */}
        <div className="services">
          <h3 className="services_main_h3">TJÄNSTER</h3>
          <p className="services_p services_description">Jag jobbar med allt från kortare konsultationer till hela inredningskoncept. Utgångspunkten är oftast en önskan om att göra en förändring i sin hemmiljö vare sig det handlar om att rensa ut onödiga saker, möblera om eller hitta en personlig stil.</p>
        </div>
        {/* SERVICES LIST PART */}
        <div className="services_list">
          <div className="services_list_item">
            <h3 className="services_h3">INREDNING</h3>
            <img className='services_icon' src={StolIcon} alt="Icon" />
            <p className="services_p"> • materialval och möblering<br></br> • hjälp att välja färg & tapeter<br></br> • hitta en hållbar inredningsstil</p>
          </div>
           <div className="services_list_item">
            <h3 className="services_h3">RENSNING & ORGANISERING</h3>
            <img className='services_icon' src={SkapIcon} alt="Icon" />
            <p className="services_p"> • rensa vindsförråd och källare<br></br> •  överblick över prylar & kläder<br></br> • skapa en hall med bra förvaring
            </p>
          </div>
           <div className="services_list_item">
            <h3 className="services_h3">HOMESTYLING</h3>
            <img className='services_icon' src={LampIcon} alt="Icon" />
            <p className="services_p"> • rådgivning inför försäljning<br></br> • visualisering av inredningsalternativ
            </p>
          </div>
        </div>
        {/* BACKGRUND BILD PART */}
        <div className="background_image">
          <div className="background_image_text">
            <p>Vill du boka konsultation?<br></br> Hör av dig med ditt projekt. Jag jobbar både<br></br>med privatpersoner och företag</p>
            <button className='contacts_btn'><Link className='contacts_btn' to="/contacts">KONTAKTA MIG</Link></button>
          </div>
        </div>
        <div className="services">
          <h3 className="services_main_h3 priser_h3">PRISEXEMPEL</h3>
        </div>
        {/* SERVICES LIST PART */}
        <div className="services_list priser_list">
          <div className="services_list_item">
            <h3 className="services_h3">INREDNINGSKONSULTATION</h3>
            <img className='services_icon' src={StolIcon} alt="Icon" />
            <p className="services_p"> På plats i din bostad - 2 timmar<br></br>
               Pris: 1700 kr inkl. moms
            </p>
          </div>
           <div className="services_list_item">
            <h3 className="services_h3">ORGANISERING & RENSNING</h3>
            <img className='services_icon' src={SkapIcon} alt="Icon" />
            <p className="services_p"> Uppstartsmöte - 1 timme.<br></br>
               Pris: 900 kr - därefter 370 kr/ timme inkl. moms - 
              <span>(efter RUT-avdrag)<br></br></span>
            </p>
          </div>
           <div className="services_list_item">
            <h3 className="services_h3">HOMESTYLING</h3>
            <img className='services_icon' src={LampIcon} alt="Icon" />
            <p className="services_p"> Rådgivning inför försäljning - 2 timmar<br></br>
               Pris från 3500 kr inkl. moms
            </p>
          </div>
        </div>
        <Slider />
    </div>
  )
}

export {Homepage}