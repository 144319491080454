import React from 'react';
import {Link} from 'react-router-dom';

function NotFound() {
  return (
    <div className='notfound_container'>
      <div className='notfound_text'>404</div>
      <div className='notfound_text'>Den här sidan finns inte. Gå till:</div>
      <Link className='notfound_link' to="/">Hem</Link>
    </div>
  )
}

export {NotFound}