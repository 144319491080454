import React from "react";
import '../styles/Header.css';
import { Link, Outlet } from 'react-router-dom';
import {Footer} from './Footer'
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import {Navbar} from './Navbar'


function Header() {
  return (
    <>
      <Navbar />

      <div className="main">
      
        <Outlet/>
      
      </div>

      <Footer/>
    </>
  );
}

export {Header};