import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/Footer.css'
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";


function Footer() {
  return (
    <div className='footer_container'>
      <div className="om_mig_footer">
        <h3 className='footer_h3'>OM MIG</h3>
        <p className='footer_p'>Jag är inredare och designer och har sedan 2006 jobbat för H&M, Åhléns, Arket, Gant och Cervera. Utbildad på Konstfack och certifierad inredare och homestagingkonsult via Inredningskurser.se. Jag jobbar i Stockholm och Norrtälje med omnejd, men tar även uppdrag på andra orter i mån av tid.
        </p>
      </div>
      {/* <footer>
          <Link className='footer_link' to="/">Hem</Link>
          <Link className='footer_link' to="/about">Om mig</Link>
          <Link className='footer_link' to="/services">Tjänser</Link>
          <Link className='footer_link' to="/prices">Priser</Link>
          <Link className='footer_link' to="/portfolio">Portfolio</Link>
          <Link className='footer_link' to="/contacts">Kontakter</Link>
          <Link className='footer_link' to="/404">404</Link>
      </footer> */}
      <div className="contact_email">
        <a className='footer_contact bottom' href="www.viksinterior.se" target="_blank">Telefon: 070-275 10 53</a>
        <a className='footer_contact' href="mailto:jenny@viksinterior.se">jenny@viksinterior.se</a>
        <a className='footer_contact bottom' href="www.viksinterior.se" target="_blank">www.viksinterior.se</a>
      </div>
      <div className="links_footer">
        <div className="soc_media">
          {/* <a className='footer_icon' href="https://www.facebook.com/" target="blank">
            <FaFacebook />
          </a>
          <a className='footer_icon' href="https://www.instagram.com/" target="blank">
            <FaInstagram />
          </a> */}
          <a className='footer_icon' href="https://www.linkedin.com/" target="blank">
            <FaLinkedin />
          </a>
        </div>
        <div className="soc_footer">
          
      </div>
    </div>
  </div>
  )
}

export {Footer}