import React, { useRef } from 'react'
import '../App.css';
import '../styles/Contacts.css'
import Jenny from '../images/jenny.jpeg'

// EMAILJS

import emailjs from '@emailjs/browser';


export const Contacts = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1m0w8jn', 'template_77zhvra', form.current, 'IAf3etytOmfZmoBns')
      .then((result) => {
          console.log(result.text);
          alert('Message sent');
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="email_container">
          <div className="contacts">
            <div className='email_text'>
              <h3 className='contact_title'>KONTAKT</h3>
              <div className="email_image">
                <img className='user_image' src={Jenny} alt="Jenny Nyberg bild" />
              </div>
              <p className='contacts_p'> Jag ser fram emot att höra mer om ditt projekt!
              Skriv kortfattat vad du vill ha hjälp med så återkommer jag inom kort.
              Vill du hellre bli uppringd? Skicka ett mail med ditt telefonnummer, önskad tid för samtal och "Ring mig" i ämnesraden så hör jag av mig.
               </p>
            </div>
          </div>
        <form ref={form} onSubmit={sendEmail}>
          {/* <h2 className='h2_contacts'>Skriv till mig:</h2> */}
          <label>Namn</label>
          <input type="text" name="name" placeholder='Skriv namn'/>
          <label>Epost</label>
          <input type="email" name="email" placeholder='Skriv epost'/>
          <label>Telefon</label>
          <input type="phone" name="phone" placeholder='Skriv telefonnummer'/>
          <label>Meddelande</label>
          <textarea type="message" name="message" placeholder='Skriv meddelande'/>
          <input className='Submit' type="submit" value="Skicka" />
        </form>
    </div>
  );
};