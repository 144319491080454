import React from 'react'
import '../App.css';
import '../styles/About.css'

import DinnerMoodboard from '../images/Mb5.png'
import Two from '../images/Two.png'
import One from '../images/One.png'


function About() {
  return (
      <div className="line">
        <img className="processen_img" src={DinnerMoodboard} alt="Process" />
        <div className="processen_text">
          <h3 className='processen_h3'>INREDNING</h3>
          <p className='processen_italic'>När du vill förändra ett rum, ska flytta till ny bostad eller vill bolla idéer.</p>
          <p className='processen_p'>Projektet inleds med ett 2 timmars inspirationsmöte på plats hos dig. Vi går igenom rummens möjligheter, förslag på möblering, väggfärg, tapet, textilier och belysning. Du får också tips på smart förvaring och hur du kan utnyttja rummen på bästa sätt. Efter mötet väljer du antingen att utföra jobbet på egen hand eller ta hjälp av mig för visualisering och utförande.</p>
          <p className='processen_bold'>Pris inspirationsmöte: 1700 kr inkl. moms.</p>
        </div>
        <img className="processen_img one" src={Two} alt="Process" />
        <div className="processen_text">
          <h3 className='processen_h3'>RENSNING & ORGANISERING</h3>
          <p className='processen_italic'>När du vill frigöra utrymme, rensa bland prylar och kläder och hitta smarta och snygga förvaringslösningar.</p>
          <p className='processen_p'>Projektet börjar med en konsultation på plats, tidsåtgång 1 timme. Tillsammans tittar vi på de utrymmen som ska ordnas upp och gör en planering för hur arbetet ska gå till. Vi tittar även på förvaringslösningar för att skapa en bättre struktur. Du utför sedan jobbet på egen hand eller med hjälp av mig.
          Jag delar aldrig före- och efterbilder eller annan information och har stor förståelse för de känslor som kan komma upp under arbetets gång.</p>
          <p className='processen_bold'>Pris konsultationsmöte: 900 kr inkl. moms</p>
          <p className='processen_italic'>Jobb utöver konsultationen debiteras per timme.<br></br>Pris 370 kr/ timme inkl. moms (efter RUT-avdrag)</p>
        </div>
        <img className="processen_img two" src={Two} alt="Process" />
        <img className="processen_img" src={One} alt="Process" />
        <div className="processen_text">
          <h3 className='processen_h3'>HOMESTYLING</h3>
          <p className='processen_subtitle'>KONSULTATION</p>
          <p className='processen_p'>Passar dig som vill få råd om hur du kan locka fler köpare till visningen genom att förändra delar av inredningen. En stylingkonsultation tar 1-3 timmar beroende på bostadens storlek och sker på plats hos dig. Under mötet går jag igenom rum för rum och tittar på vad som kan adderas och plockas bort för att skapa en miljö som tilltalar många. Du antecknar och utför sedan stylingen själv.</p>
          <p className='processen_bold'>Pris varierar beroende på bostadens storlek, från 3500 kr inkl. moms.</p>
          <p className='processen_subtitle'>HEL HOMESTYLING</p>
          <p className='processen_p'>Vill du att jag tar hand om styling, inköp, rensning och bortforsling av skräp?
          Hör av dig så skickar jag offert.</p>
        </div>
      </div>
  )
}

export {About}